import React, { Fragment } from 'react'

import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

const FeatureComparisonTable = ({ data }) => (
  <TableContainer sx={{ width: 'auto', maxWidth: '100%', m: 'auto' }}>
    <Table
      size="small"
      sx={{
        '& .MuiTableCell-root': {
          borderBottom: 'none',
        },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell />
          {data.editions.map((e) => (
            <TableCell
              key={`e-${e.label}`}
              width={200}
              align="center"
              // sx={{ textTransform: 'uppercase' }}
            >
              <Typography variant="h5">{e.label}</Typography>
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell />
          {data.editions.map((e) => (
            <TableCell
              key={`e-${e.label}-d`}
              width={200}
              align="center"
              sx={{ fontWeight: 'normal' }}
            >
              {e.description}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.featureGroups.map((fg) => (
          <Fragment key={`fg-${fg.label}`}>
            <TableRow>
              <TableCell
                colSpan={1 + data.editions.length}
                sx={{
                  pt: 4,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
              >
                <Typography variant="h6">{fg.label}</Typography>
              </TableCell>
            </TableRow>
            {fg.features.map((f) => (
              <TableRow
                key={`fg-${fg.label}-f-${f.label}`}
                sx={(theme) => ({
                  '&:nth-of-type(odd)': {
                    background: theme.palette.action.hover,
                  },
                  '&:nth-of-type(even)': {
                    background: theme.palette.action.selected,
                  },
                })}
              >
                <TableCell sx={{ pl: 4 }}>{f.label}</TableCell>
                {data.editions.map((e) => (
                  <TableCell
                    key={`fg-${fg.label}-f-${f.label}-e-${e.label}`}
                    sx={(theme) => ({
                      borderLeft: `1px solid ${theme.palette.divider}`,
                    })}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      {f.availability[e.id].value === false && (
                        <CancelOutlined color="error" />
                      )}
                      {f.availability[e.id].value === true && (
                        <CheckCircleOutlined color="success" />
                      )}
                      {f.availability[e.id].text && (
                        <Typography textAlign="center">
                          {f.availability[e.id].text}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </Fragment>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

export default FeatureComparisonTable

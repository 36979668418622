import React from 'react'

import { Box, Container, Typography } from '@mui/material'

import hero from '~/assets/images/ui/simulation_2000x1125.png'
import L from '~/components/Helpers/Lang'

import DashboardProductList from './DashboardProductList'

const Dashboard = () => (
  <Box>
    <Box
      sx={(theme) => ({
        background:
          theme.palette.sky[
            { dark: 'night', light: 'day' }[theme.palette.mode]
          ],
      })}
    >
      <Container
        sx={{
          height: 260,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: `url(${hero}) center center no-repeat`,
            backgroundSize: 'cover',
            ...(theme.palette.mode === 'light' && {
              filter: 'hue-rotate(-50deg) brightness(125%)',
            }),
          })}
        />
        <Box px={2} zIndex={1}>
          <Typography variant="h2" sx={{ mb: 2, fontWeight: 'normal' }}>
            <L>hero.title</L>
          </Typography>
          <Typography variant="h5">
            <L>hero.subtitle</L>
          </Typography>
        </Box>
      </Container>
    </Box>
    <Container sx={{ py: 2 }}>
      <Typography gutterBottom>
        <L>productlist.subtitle</L>
      </Typography>
      <DashboardProductList />
    </Container>
  </Box>
)

export default Dashboard
